<template>
  <div class="data-box">
    <van-nav-bar
        title="用餐安排"
        left-text="返回"
        right-text=""
        left-arrow
        fixed
        z-index="1000"
        placeholder
        safe-area-inset-top
        @click-left="onClickLeft"
    />
    <div class="data-box-head">
      活动期间用餐时间将按时安排，如因工作原因无法<br/>
      就餐，请提前与餐食负责人联系，离场时须完整交回
    </div>
    <div class="data-box-body">
      <div class="body-div">
        <div class="body-div-item">
          <span class="span-head">住宿安排负责人</span>
          <span class="span-name align-right">{{ personInfo.chargeName ? personInfo.chargeName : "无" }}</span>
          <a :href="'tel:' + personInfo.chargePhone" class="iconfont icon-dianhua1" v-show="personInfo.chargePhone"></a>
        </div>
      </div>
      <div class="body-div" v-if="mealsList.length > 0">
        <div class="body-div-title align-center">
          用餐安排
        </div>
        <div class="body-div-tables">
          <dl>
            <tr>
              <dt>餐点类别</dt>
              <dd v-for="item in mealsList" :key="item.diningType">
                {{["早餐","午餐","晚餐","宵夜"][item.diningType]}}
              </dd>
            </tr>
            <tr>
              <dt>用餐方式</dt>
              <dd v-for="item in mealsList" :key="item.diningType">
                {{["不安排","派餐","堂食","餐补","客户"][item.arrangeType]}}
              </dd>
            </tr>
            <tr>
              <dt>餐标价格</dt>
              <dd v-for="item in mealsList" :key="item.diningType">
                {{'￥' + item.dealNorm}}
              </dd>
            </tr>
          </dl>
        </div>
      </div>
      <div class="body-div" v-if="mealsList.length > 0 && codeUrl">
        <div v-if="restaurantList.length > 0">
          <div class="body-div-title align-center">
            请在饭点前往指定餐厅用餐
          </div>
          <van-radio-group v-model="radioIndex" @change="getWxCode">
            <div class="body-div-address" v-for="(item,index) in restaurantList" :key="index">
              <div class="address-item">
                <span class="address-item-key">堂食餐厅</span>
                <span class="address-item-value">{{ item.objName }}</span>
                <span class="address-item-copy"><van-radio :name="index"></van-radio></span>
              </div>
              <div class="address-item">
                <span class="address-item-key">餐厅地址</span>
                <span class="address-item-value show-line1">{{ item.phoneSite }}</span>
                <span id="address" class="address-item-copy" :data-clipboard-text="item.phoneSite" @click="copyText()">复制</span>
              </div>
            </div>
          </van-radio-group>
        </div>
        <div class="body-div-address" v-if="sendMealInfo.length > 0">
          <div class="address-item">
            <span class="address-item-key">派餐餐点</span>
            <span class="address-item-value" style="width: 40%;">{{["早餐","午餐","晚餐","宵夜"][(sendMealInfo[0].type-2)]}}</span>
            <span class="address-item-copy" style="width: 34%;" @click="showMealsAddress">
              领餐地点&nbsp;
              <i class="iconfont icon-07jiantouxiangyoufill"></i>
            </span>
          </div>
        </div>
        <div class="body-div-code">
          <vue-qr :text="codeUrl" :size="200"></vue-qr>
        </div>
        <div class="body-div-prompt align-center">
          请展示给餐厅工作人员扫码
        </div>
      </div>
    </div>
    <van-overlay :show="$store.state.showOverlay" :z-index="1010">
      <van-loading class="loading-box" type="spinner" color="#1989fa"/>
    </van-overlay>
  </div>
</template>

<script>
import vueQr from "vue-qr";
import Clipboard from "clipboard";
import {
  personFoodArrange,
  getWxCode
} from "@/api/index";

export default {
  name: "parMeals",
  data() {
    return {
      arrangeType: 0,
      diningType: 0,
      radioIndex: 0,
      personInfo: {},//负责人信息
      mealsList: [],//用餐安排列表
      sendMealInfo: [],//派餐信息列表
      restaurantList: [],//堂食信息列表
      getMeals: [],//已领取的餐食数据，为空时表示未领取
      codeUrl: ""
    }
  },
  components: {
    vueQr
  },
  mounted() {
    let that = this;
    that.initData();
  },
  methods:{
    initData(){
      let that = this;
      let activitySets = that.$store.getters.getOPDetails.activitySets;
      that.mealsList = that.$store.getters.getOPDetails.activityFoodSets;
      activitySets.forEach(item => {
        if(item.relateType === 3){
          that.personInfo = item;
        }
      })
      that.getMealsInfo();
    },
    getMealsInfo(){
      let that = this;
      let params = {
        ayId: that.$store.getters.getOPDetails.id
      }
      personFoodArrange(params).then(res => {
        that.$tool.resValidation(res, () => {
          that.diningType = res.data.diningType;
          that.arrangeType = res.data.arrangeType;
          that.sendMealInfo = res.data.sendMealInfo ? res.data.sendMealInfo : [];
          that.restaurantList = res.data.restaurantList ? res.data.restaurantList : [];
          that.getMeals = res.data.getMeals ? res.data.getMeals : [];
          that.getWxCode();
        })
      })
    },
    getWxCode(){
      let that = this;
      let id = that.$store.getters.getActObjId;
      let type = that.diningType;
      if(that.arrangeType === 1){
        that.codeUrl = that.$store.state.baseURL + "#/parMeals_send_code?id=" + id + "&type=" + type;
      }else if(that.arrangeType === 2){
        let room = that.restaurantList[that.radioIndex].objName;
        let address = that.restaurantList[that.radioIndex].phoneSite;
        that.codeUrl = that.$store.state.baseURL + "#/parMeals_room_code?id=" + id + "&type=" + type + "&room=" + room + "&address=" + address;
      }
      /*let url = that.$store.state.baseURL + "#/parMeals_send_code";
      let params = {
        app_id: that.$store.state.app_id,
        redirect_uri: that.$tool.toURLEncode(url),
        state: 1
      };
      that.codeUrl = getWxCode(params);*/
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    copyText(){
      let that = this;
      let clipboard = new Clipboard("#address");
      clipboard.on("success", (e) => {
        that.$toast("复制成功");
        // 释放内存
        clipboard.destroy();
      });
    },
    showMealsAddress(){
      let that = this;
      that.$router.push({
        name: 'parMeals_address',
        query: {
          sendMealList: that.sendMealInfo
        }
      });
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/common.scss";
.body-div-address{
  width: 100%;
  margin: 10px 0px;
  background-color: #F6F9FF;
  padding: 10px 0px;
  border-radius: 10px;
  .address-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 16px;
    .address-item-key{
      width: 26%;
      height: 36px;
      line-height: 36px;
      color: #8B8FAD;
      font-size: 14px;
    }
    .address-item-value{
      width: 60%;
      color: #3E4576;
      font-size: 14px;
    }
    .address-item-copy{
      width: 14%;
      color: #3476FF;
      font-size: 14px;
    }
  }
}
</style>